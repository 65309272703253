import React, { useCallback, useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./Header.styles";
import { AppBar, Avatar, Collapse, Drawer, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, SvgIcon, Toolbar, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link as RouterLink, useHistory, withRouter } from "react-router-dom";
import { ReactComponent as wingsLogo } from "./../../assets/icons/wings-logo.svg";
import { ReactComponent as planningLogoIcon } from "./../../assets/icons/delivery-icon.svg";
import { ReactComponent as usersNavLogoIcon } from "./../../assets/icons/users-icon.svg";
import { ReactComponent as dashboardIcon } from "./../../assets/icons/dashboard-icon-bars.svg";
import { ReactComponent as hubsIcon } from "./../../assets/icons/hubs-icon.svg";
import { ReactComponent as fleetIcon } from "./../../assets/icons/fleet-icon.svg";
import { ReactComponent as trackingIcon } from "./../../assets/icons/tracking-icon.svg";
import { ReactComponent as adminstrationIcon } from "./../../assets/icons/admin-logo-icon.svg";
import { ReactComponent as delveIcon } from "./../../assets/icons/circle-wavy-check-delve.svg";
import { ReactComponent as downArrowIcon } from "./../../assets/icons/down-arrow-icon.svg";
import { ReactComponent as rightArrowIcon } from "./../../assets/icons/right-arrow-icon.svg";
import { ReactComponent as codReconIcon } from "./../../assets/icons/cod-recon-icon.svg";
import { ReactComponent as allOrdersIcon } from "./../../assets/icons/all-orders-icon.svg";
import PersonIcon from "@material-ui/icons/Person";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../Login/redux/loginSlice";
import AppConstants from "../../constants";
import SelectBox, { SelectItemProps } from "../SelectBox";
import SearchableMultipleDropdown from "../SearchableMultipleDropdown/index";
import {
  countryCodeUpdate,
  fetchCommonDropdownValues,
  fetchCountryConfigDetils,
  fetchCountryHubDetailsDetils,
  fetchCwingsReportCountriesList,
  fetchUserDetailsDropdownValues,
  hubCodeUpdate,
} from "../../config/redux/common/commonSlice";
import { resetPlanningData } from "../../RoutePlanning/redux/planningSlice";
import { AppState } from "../../config/redux/reducers";
import { isEqualArrays, isNull, isUndefined, isFeatureEnabledForCountry } from "../../utils/helpers.utils";
import { ReactComponent as stdDeliveryIcon } from "./../../assets/icons/standard-delivery-icon.svg";
import { ReactComponent as offlineDeliveryIcon } from "./../../assets/icons/offline-delivery-icon.svg";
import { ReactComponent as delveGroupIcon } from "./../../assets/icons/delve-icon.svg";
import { ReactComponent as expressDeliveryIcon } from "./../../assets/icons/bike.svg";
import { customerCommunicationFeatureEnabledForCountries } from "../../config/Api.config";

const Header = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const initialRender = useRef(true); // Track the first render

  const { countryCode, cwingsReportCountriesList, hubRecordList, hubRecordListWithCodeAndName } = useSelector((state: AppState) => state.common);
  const {
    isCollectionPointManager,
    isAdmin,
    isCHDManager,
    isVTManager,
    isQCManager,
    isDriver,
    isDispatcher,
    isOnlyExpressDispatcher,
    isOnlyStandardDispatcher,
    isLastMileViewer,
    isOnlyStdLastMileViewer,
    isOnlyExpLastMileViewer,
  } = useSelector((state: AppState) => state.userLogin);

  const { location, openSideMenuOnClick, handleDrawerOnClick } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [afterUpdate, setAfterUpdate] = useState(false);
  const [openHeaders, setOpenHeaders] = useState<string[]>([]);
  const [selectedNav, setSelectedNav] = useState(false);
  const [tabValue, setTabValue] = useState(location.pathname.substr(1));
  const [selectedHeadergroup, setSelectedHeadergroup] = useState("");
  const [country, setCountry] = useState(sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value);
  const [hub, setHub] = useState(JSON.parse(sessionStorage.getItem(AppConstants.HUB_CODE) || "[]"));
  const [title, setTitle] = useState("");
  const [headerState, setHeaderState] = useState<{ activeStatus: boolean }>({ activeStatus: true });
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenHeaders([]);
    setOpenDrawer(false);
  };

  const toggleDrawer = useCallback(() => {
    handleDrawerOnClick(!openDrawer);
    openDrawer ? handleDrawerClose() : handleDrawerOpen();
  }, [openDrawer, handleDrawerOnClick]);

  const handleUserLogout = useCallback(() => {
    dispatch(userLogout());
  }, [dispatch]);

  const fetchCommonDetails = (value?: string) => {
    dispatch(fetchCommonDropdownValues({ countryCode: value }));
  };

  const handleCountryChange = (value: string) => {
    setCountry(value);
    dispatch(countryCodeUpdate({ countryCode: value }));
    dispatch(resetPlanningData());
    fetchCommonDetails(value);
  };

  const handleHubChange = useCallback((value: any) => {
    setHub(value);
  }, []);

  const getActiveRoute = useCallback(
    (routes: any[]) => {
      return routes.findIndex((item: any) => (item.hasParams ? `/${tabValue}`.indexOf(item.route) > -1 : item.route === `/${tabValue}`)) > -1;
    },
    [tabValue]
  );

  const handleDrawerHover = useCallback(() => {
    if (openSideMenuOnClick) {
      return;
    }
    handleDrawerOpen();
  }, [openSideMenuOnClick]);

  const handleDrawerHoverExit = useCallback(() => {
    if (openSideMenuOnClick) {
      return;
    }
    handleDrawerClose();
  }, [openSideMenuOnClick]);

  const getCurrentRole = () => {
    let role = "";
    const currentRoles = sessionStorage.getItem("role");
    if (currentRoles) {
      const currentRole = JSON.parse(currentRoles)[0];
      if (currentRole) {
        role = currentRole.value;
      }
    }
    return role;
  };

  const handleHeaderClick = (headerId: string, headGroup: any[]) => {
    const headerIndex = openHeaders.indexOf(headerId);
    let redirectTabValue = "";
    if (headGroup.length) {
      const selectedIndex = headGroup.findIndex((header: any) => header.route === `/${tabValue}`);
      if (selectedIndex > -1) {
        setSelectedNav(!selectedNav);
        redirectTabValue = headGroup[selectedIndex].isListItem ? headGroup[selectedIndex].route : headGroup[0].route;
      } else {
        setSelectedNav(true);
        redirectTabValue = headGroup[0].route;
      }
    }

    let headerValues = [...openHeaders];
    if (headerIndex > -1) {
      headerValues.splice(headerIndex, 1);
    } else {
      headerValues.push(headerId);
    }

    if (tabValue !== redirectTabValue) {
      history.push(redirectTabValue);
    }
    setOpenHeaders(headerValues);
  };

  const checkOpenHeader = useCallback(
    (headerId: string) => {
      return selectedNav && openDrawer && selectedHeadergroup === headerId;
    },
    [openHeaders, openDrawer, selectedHeadergroup, selectedNav]
  );

  const fetchUserDetails = () => {
    // dispatch(fetchUserDetailsDropdownValues());
    dispatch(fetchCwingsReportCountriesList());
    if (initialRender.current) {
      initialRender.current = false; // Set to false after the first render
    } else {
      const countryCode = sessionStorage.getItem(AppConstants.COUNTRY_CODE);
      let deliveryType = null;

      if (history?.location?.pathname) {
        const pathName = history.location.pathname;
        if (pathName.includes(AppConstants.ROUTES.STANDARD)) {
          deliveryType = "STANDARD";
        } else if (pathName.includes(AppConstants.ROUTES.EXPRESS_PAGE)) {
          deliveryType = "EXPRESS";
        }
        dispatch(fetchCountryHubDetailsDetils({ countryCode, deliveryType }));
      } else {
        dispatch(fetchCountryHubDetailsDetils({ countryCode }));
      }
    }
  };

  useEffect(() => {
    if (!isNull(countryCode)) {
      fetchUserDetails();
      setAfterUpdate(true);
      dispatch(fetchCountryConfigDetils({ activeStatus: headerState.activeStatus }));
    }
  }, [countryCode]);

  useEffect(() => {
    if (hubRecordList[countryCode] && afterUpdate) {
      const sessionHubValue = JSON.parse(sessionStorage.getItem(AppConstants.HUB_CODE) || "[]");
      const ddnValues = [AppConstants.SELECT_ALL.value, ...hubRecordList[countryCode].map((hub: SelectItemProps) => hub.value)];
      if (!isEqualArrays(sessionHubValue, ddnValues)) {
        setHub(ddnValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubRecordList]);

  useEffect(() => {
    dispatch(hubCodeUpdate(hub));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hub]);

  useEffect(() => {
    setTabValue(location.pathname.substr(1));
    setSelectedHeadergroup(location.pathname.split("/")[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    fetchCommonDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (history?.location?.pathname) {
      if (initialRender.current) {
        initialRender.current = false; // Set to false after the first render
      } else {
        let deliveryType = null;
        if (history.location.pathname.includes(AppConstants.ROUTES.STANDARD)) {
          deliveryType = "STANDARD";
        } else if (history.location.pathname.includes(AppConstants.ROUTES.EXPRESS_PAGE)) {
          deliveryType = "EXPRESS";
        }
        dispatch(fetchCountryHubDetailsDetils({ countryCode, deliveryType }));
      }
      switch (history.location.pathname) {
        case AppConstants.ROUTES.VALETTROLLEY:
          setTitle("Valet Trolley");
          break;
        case AppConstants.ROUTES.PLANNING:
          setTitle("Planning");
          break;
        case AppConstants.ROUTES.TRACKING:
          setTitle("Tracking");
          break;
        case AppConstants.ROUTES.CLICKNCOLLECT:
          setTitle("Click And Collect");
          break;
        case AppConstants.ROUTES.EXPRESS:
          setTitle("Express Lebanon");
          break;
        case AppConstants.ROUTES.HUBS:
          setTitle("Hubs");
          break;
        case AppConstants.ROUTES.USERS:
          setTitle("Users");
          break;
        case AppConstants.ROUTES.CARREFOUR_HOME_DLVRY:
          setTitle("Home Delivery");
          break;
        case AppConstants.ROUTES.DELVE_DASHBOARD:
          setTitle("Delve Dashboard");
          break;
        case AppConstants.ROUTES.CARREFOUR_NOW:
          setTitle("Express Orders");
          break;
        case AppConstants.ROUTES.POS:
          setTitle("POS Management");
          break;
        case AppConstants.ROUTES.DRIVERS:
          setTitle("Driver Management");
          break;
        case AppConstants.ROUTES.COD_PORTAL:
          setTitle("COD Reconciliation");
          break;
        case AppConstants.ROUTES.STD_COD_PORTAL:
          setTitle("COD Reconciliation");
          break;
        case AppConstants.ROUTES.DELVE_ORDER_LIST:
          setTitle("Delve Order List");
          break;
        case AppConstants.ROUTES.ALL_ORDERS:
          setTitle("All Orders");
          break;
        case AppConstants.ROUTES.PERFORMANCE:
          setTitle("Planning Dashboard");
          break;
        default:
          setTitle("");
          break;
      }
    }
  }, [history.location.pathname]);

  // Read image data from sessionStorage on component mount
  useEffect(() => {
    const imageData = sessionStorage.getItem("images");
    if (imageData) {
      const parsedData = JSON.parse(imageData);
      setImageUrl(parsedData.md);
    }
  }, []);

  const getMenuForAdminOrDispatcher = () => {
    return (
      <>
        {getStdDeliveryMenu()}
        {!isOnlyStandardDispatcher && !isOnlyStdLastMileViewer && getExpDeliveryMenu()}
        {getOfflineDeliveryMenu()}
        {/* {getDelveMenu()} */}
      </>
    );
  };

  const getMenuForOnlyExpressDispatcher = () => {
    return <Grid>{getExpDeliveryMenu()}</Grid>;
  };

  const hideHubInTopMenu = (pathname: any) => {
    if (!isUndefined(pathname) && !isNull(pathname)) {
      switch (pathname) {
        case AppConstants.ROUTES.DELVE_DASHBOARD:
        case AppConstants.ROUTES.DELVE_ORDER_LIST:
        case AppConstants.ROUTES.HUBS:
        case AppConstants.ROUTES.POS:
        case AppConstants.ROUTES.COD_PORTAL:
        case AppConstants.ROUTES.STD_COD_PORTAL:
        case AppConstants.ROUTES.STAGING_AREA:
        case AppConstants.ROUTES.CUSTOMER_COMMUNICATION:
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  const getStdDeliveryMenu = () => {
    return (
      <Grid>
        <ListItem
          className="listItem"
          button
          selected={getActiveRoute(AppConstants.ROUTES.STD_DELIVERIES_GROUP)}
          onClick={() => handleHeaderClick(AppConstants.ROUTES.STD_DELIVERIES_GROUP_ID, AppConstants.ROUTES.STD_DELIVERIES_GROUP)}
        >
          <ListItemIcon>
            <SvgIcon className="listIcon" viewBox="0 0 26 24" component={stdDeliveryIcon} />
          </ListItemIcon>
          <ListItemText primary="Standard Delivery" />
          {checkOpenHeader(AppConstants.ROUTES.STD_DELIVERIES_GROUP_ID) ? (
            <SvgIcon className="dropdownIcon" component={downArrowIcon} viewBox="0 0 21 21" />
          ) : (
            <SvgIcon className="dropdownIcon" component={rightArrowIcon} viewBox="0 0 21 21" />
          )}
        </ListItem>
        <Collapse in={checkOpenHeader(AppConstants.ROUTES.STD_DELIVERIES_GROUP_ID)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.PLANNING}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.PLANNING,
                },
              ])}
            >
              <ListItemText primary="Planning" />
            </ListItem>
            {countryCode === AppConstants.COUNTRY_LB && (
              <ListItem
                className="listItem subItem"
                button
                component={RouterLink}
                to={AppConstants.ROUTES.EXPRESS}
                selected={getActiveRoute([
                  {
                    route: AppConstants.ROUTES.EXPRESS,
                  },
                ])}
              >
                <ListItemText primary="Express Lebanon" />
              </ListItem>
            )}
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.PERFORMANCE}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.PERFORMANCE,
                },
              ])}
            >
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.TRACKING}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.TRACKING,
                },
              ])}
            >
              <ListItemText primary="Tracking" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.HUBS}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.HUBS,
                },
              ])}
            >
              <ListItemText primary="Hubs" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.STD_COD_PORTAL}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.STD_COD_PORTAL,
                },
              ])}
            >
              <ListItemText primary="COD Reconciliation" />
            </ListItem>
          </List>
        </Collapse>
      </Grid>
    );
  };

  const getExpDeliveryMenu = () => {
    return (
      <Grid>
        <ListItem
          className="listItem"
          button
          selected={getActiveRoute(AppConstants.ROUTES.EXP_DELIVERIES_GROUP)}
          onClick={() => handleHeaderClick(AppConstants.ROUTES.EXP_DELIVERIES_GROUP_ID, AppConstants.ROUTES.EXP_DELIVERIES_GROUP)}
        >
          <ListItemIcon>
            <SvgIcon className="listIcon" viewBox="0 0 26 24" component={expressDeliveryIcon} />
          </ListItemIcon>
          <ListItemText primary="Express Delivery" />
          {checkOpenHeader(AppConstants.ROUTES.EXP_DELIVERIES_GROUP_ID) ? (
            <SvgIcon className="dropdownIcon" component={downArrowIcon} viewBox="0 0 21 21" />
          ) : (
            <SvgIcon className="dropdownIcon" component={rightArrowIcon} viewBox="0 0 21 21" />
          )}
        </ListItem>
        <Collapse in={checkOpenHeader(AppConstants.ROUTES.EXP_DELIVERIES_GROUP_ID)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.CARREFOUR_NOW}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.CARREFOUR_NOW,
                },
              ])}
            >
              <ListItemText primary="Express Delivery" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.DRIVERS}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.DRIVERS,
                },
              ])}
            >
              <ListItemText primary="Drivers" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.POS}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.POS,
                },
              ])}
            >
              <ListItemText primary="POS" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.COD_PORTAL}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.COD_PORTAL,
                },
              ])}
            >
              <ListItemText primary="COD Reconciliation" />
            </ListItem>
          </List>
        </Collapse>
      </Grid>
    );
  };

  const getValleyTrollyMenu = () => {
    return (
      <Grid>
        <ListItem
          className="listItem"
          button
          selected={getActiveRoute(AppConstants.ROUTES.VT_CONFIG.VT_GROUP)}
          onClick={() => handleHeaderClick(AppConstants.ROUTES.VT_CONFIG.VT_GROUP_ID, AppConstants.ROUTES.VT_CONFIG.VT_GROUP)}
        >
          <ListItemIcon>
            <SvgIcon className="listIcon" viewBox="0 0 26 24" component={offlineDeliveryIcon} />
          </ListItemIcon>
          <ListItemText primary="Valet Trolly" />
          {checkOpenHeader(AppConstants.ROUTES.VT_CONFIG.VT_GROUP_ID) ? (
            <SvgIcon className="dropdownIcon" component={downArrowIcon} viewBox="0 0 21 21" />
          ) : (
            <SvgIcon className="dropdownIcon" component={rightArrowIcon} viewBox="0 0 21 21" />
          )}
        </ListItem>
        <Collapse in={checkOpenHeader(AppConstants.ROUTES.VT_CONFIG.VT_GROUP_ID)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.VALETTROLLEY}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.VALETTROLLEY,
                },
              ])}
            >
              <ListItemText primary="Valet Trolly" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.VT_CONFIG.Routes.Tracking}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.VT_CONFIG.Routes.Tracking,
                },
              ])}
            >
              <ListItemText primary="Tracking" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.VT_CONFIG.Routes.Hubs}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.VT_CONFIG.Routes.Hubs,
                },
              ])}
            >
              <ListItemText primary="Hubs" />
            </ListItem>
          </List>
        </Collapse>
      </Grid>
    );
  };

  const getClickNCollectMenu = () => {
    return (
      <Grid>
        <ListItem
          className="listItem"
          button
          selected={getActiveRoute(AppConstants.ROUTES.CNC_CONFIG.CNC_GROUP)}
          onClick={() => handleHeaderClick(AppConstants.ROUTES.CNC_CONFIG.CNC_GROUP_ID, AppConstants.ROUTES.CNC_CONFIG.CNC_GROUP)}
        >
          <ListItemIcon>
            <SvgIcon className="listIcon" viewBox="0 0 26 24" component={offlineDeliveryIcon} />
          </ListItemIcon>
          <ListItemText primary="Click And Collect" />
          {checkOpenHeader(AppConstants.ROUTES.CNC_CONFIG.CNC_GROUP_ID) ? (
            <SvgIcon className="dropdownIcon" component={downArrowIcon} viewBox="0 0 21 21" />
          ) : (
            <SvgIcon className="dropdownIcon" component={rightArrowIcon} viewBox="0 0 21 21" />
          )}
        </ListItem>
        <Collapse in={checkOpenHeader(AppConstants.ROUTES.CNC_CONFIG.CNC_GROUP_ID)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.CLICKNCOLLECT}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.CLICKNCOLLECT,
                },
              ])}
            >
              <ListItemText primary="Click And Collects" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.CNC_CONFIG.Routes.Tracking}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.CNC_CONFIG.Routes.Tracking,
                },
              ])}
            >
              <ListItemText primary="Tracking" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.CNC_CONFIG.Routes.Hubs}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.CNC_CONFIG.Routes.Hubs,
                },
              ])}
            >
              <ListItemText primary="Hubs" />
            </ListItem>
          </List>
        </Collapse>
      </Grid>
    );
  };

  const getCHDMenu = () => {
    return (
      <Grid>
        <ListItem
          className="listItem"
          button
          selected={getActiveRoute(AppConstants.ROUTES.CHD_CONFIG.CHD_GROUP)}
          onClick={() => handleHeaderClick(AppConstants.ROUTES.CHD_CONFIG.CHD_GROUP_ID, AppConstants.ROUTES.CHD_CONFIG.CHD_GROUP)}
        >
          <ListItemIcon>
            <SvgIcon className="listIcon" viewBox="0 0 26 24" component={offlineDeliveryIcon} />
          </ListItemIcon>
          <ListItemText primary="Home Delivery" />
          {checkOpenHeader(AppConstants.ROUTES.CHD_CONFIG.CHD_GROUP_ID) ? (
            <SvgIcon className="dropdownIcon" component={downArrowIcon} viewBox="0 0 21 21" />
          ) : (
            <SvgIcon className="dropdownIcon" component={rightArrowIcon} viewBox="0 0 21 21" />
          )}
        </ListItem>
        <Collapse in={checkOpenHeader(AppConstants.ROUTES.CHD_CONFIG.CHD_GROUP_ID)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.CARREFOUR_HOME_DLVRY}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.CARREFOUR_HOME_DLVRY,
                },
              ])}
            >
              <ListItemText primary="Home Delivery" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.CHD_CONFIG.Routes.Tracking}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.CHD_CONFIG.Routes.Tracking,
                },
              ])}
            >
              <ListItemText primary="Tracking" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.CHD_CONFIG.Routes.Hubs}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.CHD_CONFIG.Routes.Hubs,
                },
              ])}
            >
              <ListItemText primary="Hubs" />
            </ListItem>
          </List>
        </Collapse>
      </Grid>
    );
  };

  const getSpecificOfflineMenu = () => {
    return (
      <>
        {isCollectionPointManager && getClickNCollectMenu()}
        {isVTManager && getValleyTrollyMenu()}
        {isCHDManager && getCHDMenu()}
      </>
    );
  };

  const getOfflineDeliveryMenu = () => {
    return (
      <Grid>
        <ListItem
          className="listItem"
          button
          selected={getActiveRoute(AppConstants.ROUTES.OFFLINE_DELIVERIES_GROUP)}
          onClick={() => handleHeaderClick(AppConstants.ROUTES.OFFLINE_DELIVERIES_GROUP_ID, AppConstants.ROUTES.OFFLINE_DELIVERIES_GROUP)}
        >
          <ListItemIcon>
            <SvgIcon className="listIcon" viewBox="0 0 26 24" component={offlineDeliveryIcon} />
          </ListItemIcon>
          <ListItemText primary="Other Deliveries" />
          {checkOpenHeader(AppConstants.ROUTES.OFFLINE_DELIVERIES_GROUP_ID) ? (
            <SvgIcon className="dropdownIcon" component={downArrowIcon} viewBox="0 0 21 21" />
          ) : (
            <SvgIcon className="dropdownIcon" component={rightArrowIcon} viewBox="0 0 21 21" />
          )}
        </ListItem>
        <Collapse in={checkOpenHeader(AppConstants.ROUTES.OFFLINE_DELIVERIES_GROUP_ID)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {(isAdmin || isCollectionPointManager || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer) && (
              <ListItem
                className="listItem subItem"
                button
                component={RouterLink}
                to={AppConstants.ROUTES.CLICKNCOLLECT}
                selected={getActiveRoute([
                  {
                    route: AppConstants.ROUTES.CLICKNCOLLECT,
                  },
                ])}
              >
                <ListItemText primary="Click And Collect" />
              </ListItem>
            )}
            {(isAdmin || isVTManager || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer) && (
              <ListItem
                className="listItem subItem"
                button
                component={RouterLink}
                to={AppConstants.ROUTES.VALETTROLLEY}
                selected={getActiveRoute([
                  {
                    route: AppConstants.ROUTES.VALETTROLLEY,
                  },
                ])}
              >
                <ListItemText primary="Valet Trolley" />
              </ListItem>
            )}
            {(isAdmin || isCHDManager || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer) && (
              <ListItem
                className="listItem subItem"
                button
                component={RouterLink}
                to={AppConstants.ROUTES.CARREFOUR_HOME_DLVRY}
                selected={getActiveRoute([
                  {
                    route: AppConstants.ROUTES.CARREFOUR_HOME_DLVRY,
                  },
                ])}
              >
                <ListItemText primary="Home Delivery" />
              </ListItem>
            )}
          </List>
        </Collapse>
      </Grid>
    );
  };

  const getDelveMenu = () => {
    return (
      <Grid>
        <ListItem
          className="listItem"
          button
          selected={getActiveRoute(AppConstants.ROUTES.DELVE_GROUP)}
          onClick={() => handleHeaderClick(AppConstants.ROUTES.DELVE_GROUP_ID, AppConstants.ROUTES.DELVE_GROUP)}
        >
          <ListItemIcon className="itemIcon">
            <SvgIcon className="listIcon" viewBox="0 0 26 24" component={delveGroupIcon} />
          </ListItemIcon>
          <ListItemText primary="Delve" />
          {checkOpenHeader(AppConstants.ROUTES.DELVE_GROUP_ID) ? (
            <SvgIcon className="dropdownIcon" component={downArrowIcon} viewBox="0 0 21 21" />
          ) : (
            <SvgIcon className="dropdownIcon" component={rightArrowIcon} viewBox="0 0 21 21" />
          )}
        </ListItem>
        <Collapse in={checkOpenHeader(AppConstants.ROUTES.DELVE_GROUP_ID)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.DELVE_DASHBOARD}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.DELVE_DASHBOARD,
                },
              ])}
            >
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              className="listItem subItem"
              button
              component={RouterLink}
              to={AppConstants.ROUTES.DELVE_ORDER_LIST}
              selected={getActiveRoute([
                {
                  route: AppConstants.ROUTES.DELVE_ORDER_LIST,
                },
              ])}
            >
              <ListItemText primary="Order List" />
            </ListItem>
          </List>
        </Collapse>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.root}>
      <AppBar position="fixed" className={`${classes.appBar} ${openSideMenuOnClick ? "open" : "close"}`}>
        <Toolbar className="appToolbar">
          <Grid container>
            <Grid style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
              <Typography className="logoSpanStyle">{`Wings`}</Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#1F1F1F",
                  padding: "0px 0px 0px 8px",
                  margin: "auto",
                }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid className="countrySection" item style={{ display: "flex" }}>
              {!hideHubInTopMenu(history?.location?.pathname) && (
                <Grid>
                  <SearchableMultipleDropdown
                    id="hubCode-select-box"
                    label="Hub"
                    menuItems={hubRecordListWithCodeAndName[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || []}
                    value={hub}
                    handleChange={handleHubChange}
                    disabled={false}
                  />
                </Grid>
              )}
              <Grid style={{ borderLeft: "1px solid #E6E6E6" }}>
                <SelectBox
                  className="countrySelect"
                  handleChange={handleCountryChange}
                  value={country}
                  menuPaperProps={classes.countryMenuPaper}
                  id="countryCode-select-box"
                  label="Country"
                  items={cwingsReportCountriesList}
                ></SelectBox>
              </Grid>
            </Grid>
            <Grid className="avatarSection" item>
              <Grid container alignItems="center">
                <Grid className="avatarDetails" item>
                  <Typography className="avatarItem name">{sessionStorage.getItem("name")}</Typography>
                  <Typography className="avatarItem role">{getCurrentRole()}</Typography>
                  <Link className="avatarItem logoutBtn" onClick={handleUserLogout}>
                    Logout
                  </Link>
                </Grid>
                <Grid className="avatarLogo" item>
                  <Avatar>{imageUrl ? <img src={imageUrl} alt="Profile" style={{ width: "100%", height: "100%", borderRadius: "50%" }} /> : <PersonIcon />}</Avatar>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" className={`${classes.drawer} ${openDrawer ? "open" : "close"}`}>
        <Grid container onMouseEnter={handleDrawerHover} onMouseLeave={handleDrawerHoverExit} className="drawerContainer">
          <Grid item className="toolbarSection">
            <Toolbar className="toolbar">
              <RouterLink className="logoSection" to={AppConstants.ROUTES.HOME}>
                <Grid container alignItems="center">
                  {openDrawer && <Typography className="logoSpanStyle">{`Wings`}</Typography>}
                </Grid>
              </RouterLink>
            </Toolbar>
          </Grid>
          <Grid className="menuBtnSection" item>
            <Grid
              container
              style={{
                paddingLeft: "12px",
              }}
            >
              <IconButton aria-label="Menu Button" onClick={toggleDrawer} edge="start">
                <MenuIcon className="menuButton" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item className="listSection">
            {!isDriver && (
              <List className="listContainer">
                {(isAdmin || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer) && <Grid>{getMenuForAdminOrDispatcher()}</Grid>}
                {(isOnlyExpressDispatcher || isOnlyExpLastMileViewer) && <Grid>{getMenuForOnlyExpressDispatcher()}</Grid>}
                {(isVTManager || isCHDManager || isCollectionPointManager) && getSpecificOfflineMenu()}

                {(isAdmin || isDispatcher || isLastMileViewer || isOnlyStdLastMileViewer || isOnlyExpLastMileViewer || isOnlyExpressDispatcher || isOnlyStandardDispatcher) && (
                  <>
                    <ListItem
                      className="listItem"
                      button
                      selected={getActiveRoute(AppConstants.ROUTES.ALL_ORDERS_GROUP)}
                      onClick={() => handleHeaderClick(AppConstants.ROUTES.ALL_ORDERS_GROUP_ID, AppConstants.ROUTES.ALL_ORDERS_GROUP)}
                    >
                      <ListItemIcon className="itemIcon">
                        <SvgIcon className="listIcon" viewBox="0 0 26 24" component={allOrdersIcon} />
                      </ListItemIcon>
                      <ListItemText primary="All Orders" />
                      {checkOpenHeader(AppConstants.ROUTES.ALL_ORDERS_GROUP_ID) ? (
                        <SvgIcon className="dropdownIcon" component={downArrowIcon} viewBox="0 0 21 21" />
                      ) : (
                        <SvgIcon className="dropdownIcon" component={rightArrowIcon} viewBox="0 0 21 21" />
                      )}
                    </ListItem>
                    <Collapse in={checkOpenHeader(AppConstants.ROUTES.ALL_ORDERS_GROUP_ID)} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem className="listItem subItem" button component={RouterLink} to={AppConstants.ROUTES.ALL_ORDERS} selected={getActiveRoute([{ route: AppConstants.ROUTES.ALL_ORDERS }])}>
                          <ListItemText primary="All Orders" />
                        </ListItem>
                        <ListItem className="listItem subItem" button component={RouterLink} to={AppConstants.ROUTES.RETURNS} selected={getActiveRoute([{ route: AppConstants.ROUTES.RETURNS }])}>
                          <ListItemText primary="Returns" />
                        </ListItem>
                        <ListItem
                          className="listItem subItem"
                          button
                          component={RouterLink}
                          to={AppConstants.ROUTES.DASHBOARDCAMS}
                          selected={getActiveRoute([{ route: AppConstants.ROUTES.DASHBOARDCAMS }])}
                        >
                          <ListItemText primary="Dashboard" />
                        </ListItem>
                        <ListItem
                          className="listItem subItem"
                          button
                          component={RouterLink}
                          to={AppConstants.ROUTES.EXCEPTIONS_DELIVERIES}
                          selected={getActiveRoute([{ route: AppConstants.ROUTES.EXCEPTIONS_DELIVERIES }])}
                        >
                          <ListItemText primary="Exception Deliveries" />
                        </ListItem>
                      </List>
                    </Collapse>
                  </>
                )}

                {(isAdmin || isDispatcher || isOnlyExpressDispatcher || isOnlyStandardDispatcher) && (
                  <>
                    <ListItem
                      className="listItem"
                      button
                      selected={getActiveRoute(AppConstants.ROUTES.ADMINISTRATION_GROUP)}
                      onClick={() => handleHeaderClick(AppConstants.ROUTES.ADMINISTRATION_GROUP_ID, AppConstants.ROUTES.ADMINISTRATION_GROUP)}
                    >
                      <ListItemIcon className="itemIcon">
                        <SvgIcon className="listIcon" viewBox="0 0 26 24" component={hubsIcon} />
                      </ListItemIcon>
                      <ListItemText primary="Administration" />
                      {checkOpenHeader(AppConstants.ROUTES.ADMINISTRATION_GROUP_ID) ? (
                        <SvgIcon className="dropdownIcon" component={downArrowIcon} viewBox="0 0 21 21" />
                      ) : (
                        <SvgIcon className="dropdownIcon" component={rightArrowIcon} viewBox="0 0 21 21" />
                      )}
                    </ListItem>
                    <Collapse in={checkOpenHeader(AppConstants.ROUTES.ADMINISTRATION_GROUP_ID)} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem
                          className="listItem subItem"
                          button
                          component={RouterLink}
                          to={AppConstants.ROUTES.STAGING_AREA}
                          selected={getActiveRoute([{ route: AppConstants.ROUTES.STAGING_AREA }])}
                        >
                          <ListItemText primary="Staging Locations" />
                        </ListItem>
                        {isAdmin && isFeatureEnabledForCountry(customerCommunicationFeatureEnabledForCountries, countryCode) && (
                          <ListItem
                            className="listItem subItem"
                            button
                            component={RouterLink}
                            to={AppConstants.ROUTES.CUSTOMER_COMMUNICATION}
                            selected={getActiveRoute([{ route: AppConstants.ROUTES.CUSTOMER_COMMUNICATION }])}
                          >
                            <ListItemText primary="Communications" />
                          </ListItem>
                        )}
                      </List>
                    </Collapse>
                  </>
                )}
                {!isQCManager &&
                  (isAdmin ||
                    isDispatcher ||
                    isCollectionPointManager ||
                    isCHDManager ||
                    isVTManager ||
                    isOnlyStandardDispatcher ||
                    isLastMileViewer ||
                    isOnlyStdLastMileViewer ||
                    isOnlyExpLastMileViewer ||
                    isOnlyExpressDispatcher) && (
                    <ListItem className="listItem" button component={RouterLink} to={AppConstants.ROUTES.USERS} selected={getActiveRoute([{ route: AppConstants.ROUTES.USERS }])}>
                      <ListItemIcon className="itemIcon">
                        <SvgIcon className="listIcon" viewBox="0 0 26 24" component={usersNavLogoIcon} />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItem>
                  )}
                {(isAdmin || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer || isQCManager) && getDelveMenu()}
              </List>
            )}
          </Grid>
          <Grid item className="footerSection"></Grid>
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default withRouter(Header);
